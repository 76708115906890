import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter, GLFormComponentProps, GLForm, RoleName } from 'gl-commonui';
import { setBreadcrumbs } from '@app/states/resource';
import { SchoolCoach } from './coach/home';
import { SchoolTeacher } from './teacher/home';
import { ContextHelper } from '@app/util';

interface MyActivityProps extends RouteComponentProps<any>, GLFormComponentProps {
    setBreadcrumbs?: (data) => void
}

const MyActivity = (props: MyActivityProps) => {

    if (ContextHelper.isUserMatchRole(RoleName.trainer)) {
        return <SchoolCoach {...props} />;
    }
    else if (ContextHelper.isUserMatchRole(RoleName.teacher)) {
        return <SchoolTeacher {...props} />;
    }
    else {
        return null;
    }
}

export const MyActivityPage = withRouter(connect(null, { setBreadcrumbs })(GLForm.create()(MyActivity)));

